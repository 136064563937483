<template>
    <div class="admin-settings-trnaslations">
        <b-card class="cms-pages-admin" :title="$t('Stores')">
            <b-row>
                <b-col cols="12">
                    <good-data-table 
                        ref="shopTable" 
                        :columns="fields" :no-serial="true"
                        :total-column="'response.responseData.total'" 
                        :data-column="'response.responseData.data'"
                        :api-endpoint="'/admin/store/shops'"
                         @add-new-info="toggelModal = true" 
                    />
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
    
<script>
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { dateTimeFormat, isEmpty } from '@/utilities';
export default {
    name: "StoreListing",
    components: {
        GoodDataTable,
    },
    data: () => ({
    }),
    computed: {
        fields: {
            get() {
                return [
                    {
                        field: 'store_name',
                        label: 'Store Name',
                        sortable: false,
                    },
                    {
                        field: 'email',
                        label: 'Email',
                        sortable: false,
                    },
                    {
                        field: 'country_name',
                        label: 'Country',
                        useResolver: true,
                        useHtml: true,
                        renderer: props => isEmpty(props.country) ? 'N/A' : props.country.country_name,
                    },
                    {
                        field: 'contact_no',
                        label: 'Contact No',
                        useResolver: true,
                        useHtml: true,
                        renderer: props => isEmpty(props.contact_number) ? 'N/A' : `${props.country_code}${props.contact_number}`,
                    },
                    {
                        field: 'storetype',
                        label: 'Shop Type',
                        useHtml: true,
                        renderer: props => isEmpty(props.storetype) ? 'N/A' : props.storetype.name,
                    },
                    {
                        field: 'status',
                        label: 'Status',
                        sortable: false,
                        useHtml: true,
                        renderer: props => props.status ? 'Inactive' : 'Active',
                    },
                    {
                        field: 'created_at',
                        label: 'Created At',
                        useHtml: true,
                        renderer: props => isEmpty(props.created_at) ? 'N/A' : dateTimeFormat(props.created_at, 'DD MMM, YYYY'),
                    },
                ];
            }
        }
    },
    mounted(){
        this.$refs.shopTable.loadItems();
    }
}
</script>
    